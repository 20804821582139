/**
 *
 * TODO: Clean up if flockler embed continues working
 */
// import { FlocklerSkeleton } from '@desquared/wave-components-library';
// import Script from 'next/script';
import React, { useState } from 'react';
import FlocklerEmbed from '@flockler/react-flockler-embed';

export default function Flockler({ siteuuid, embeduuid }) {
  // const [loading, setLoading] = useState(true);

  return (
    <>
      {/* <Script
        src={`https://plugins.flockler.com/embed/${siteuuid}/${embeduuid}?autoload=true`}
        async
        onLoad={() => setLoading(false)}
      ></Script>
      {loading ? <FlocklerSkeleton numLoaders={5} /> : null}
      <div id={`flockler-embed-${embeduuid}`}></div> */}
      <div>
        <FlocklerEmbed siteUuid={siteuuid} embedUuid={embeduuid} />
      </div>
    </>
  );
}
